import Swiper from 'swiper'
import Keyboard from 'swiper/modules/keyboard'
import Pagination from 'swiper/modules/pagination'
import Navigation from 'swiper/modules/navigation'
import Virtual from 'swiper/modules/virtual'

export default (context, inject) => {
  inject('swiper', Swiper)
  inject('swiperModules', {
    Keyboard,
    Pagination,
    Navigation,
    Virtual,
  })
}

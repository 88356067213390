// Static assets
import { ListingRoutesWithoutShowcaseEnum } from '~/assets/js/listing'
import { PropertyRoutesEnum } from '~/assets/js/property'
import { Sections } from '~/assets/js/sections'
import { CookieName } from '~/assets/js/cookie'

const UserPreferenceMap = {
  v: 'vendo',
  c: 'compro',
  e: 'eshop',
}

export default (context, inject) => {
  const { $device, $cookies, $config, nuxtState } = context

  // ;(function (a, b, c, d) {
  //   a = `${$config.utagURL}/utag.js`
  //   b = document
  //   c = 'script'
  //   d = b.createElement(c)
  //   d.src = a
  //   d.type = 'text/java' + c
  //   d.async = true
  //   a = b.getElementsByTagName(c)[0]
  //   a.parentNode.insertBefore(d, a)
  // })()

  window.tealiumTrack = function (a) {
    if ($config.tealiumDebug) {
      // eslint-disable-next-line no-console
      console.log(a)
    }

    window.tealiumCoda || (window.tealiumCoda = [])

    if (typeof window.tealiumCoda.track === 'function') {
      window.tealiumCoda.track(a)
      return
    }

    window.tealiumCoda.push(a)
  }

  const Tealium = {
    context,

    sectionCategory(section) {
      switch (section) {
        case Sections.VenditaImmobili:
          return 'residenziale'
        case Sections.NuoveCostruzioni:
          return 'progetto'
        case Sections.ImmobiliCommerciali:
          return 'commerciale'
        case Sections.ImmobiliDiPregio:
          return 'pregio'
        case Sections.ProprietaUnicredit:
          return 'proprieta-unicredit'
      }
    },

    propertyCategory(property) {
      if (property.type !== 'v2') {
        return this.sectionCategory(property.category_uri)
      }

      switch (true) {
        case property.source_id === 4:
          return this.sectionCategory(Sections.ProprietaUnicredit)

        case property.building.is_luxury:
          return this.sectionCategory(Sections.ImmobiliDiPregio)

        default:
          return this.sectionCategory(property.building.category.uri)
      }
    },

    track(payload) {
      try {
        window.tealiumTrack(payload)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },

    pageTrack(payload, ignoreFilters) {
      const { route, store } = this.context
      const { auth } = store.state
      const { name, path, params } = route

      // eslint-disable-next-line camelcase
      const annuncio_categoria = this.sectionCategory(params.section)

      let commonPayload = {
        event_name: 'pageview',
        page_path: path,
        site_layout:
          $device.isMobile || $device.isTablet ? 'Mobile' : 'Desktop',
        abtest: path === '/' ? 'B TEST' : '',
        // eslint-disable-next-line camelcase
        ...(annuncio_categoria ? { annuncio_categoria } : {}),
      }

      if (auth.loggedIn) {
        commonPayload = {
          ...commonPayload,
          user_id: `${auth.user.id}`,
          user_email: auth.user.email,
          user_login_status: 'Loggato',
          user_preference: UserPreferenceMap[auth.user.type],
        }

        const userVendorType =
          auth.user.seller_properties > 0 ? 'con mandato' : 'senza mandato'

        switch (true) {
          case auth.user.type !== 'v' && auth.user.seller:
            commonPayload = {
              ...commonPayload,
              user_type: 'Compratore / Venditore',
              user_vendor_type: userVendorType,
              user_dashboard: 'vendo',
            }
            break

          case auth.user.seller:
            commonPayload = {
              ...commonPayload,
              user_type: 'Venditore',
              user_vendor_type: userVendorType,
              user_dashboard: 'vendo',
            }
            break

          case auth.user.type === 'v' && !auth.user.seller:
            commonPayload = {
              ...commonPayload,
              user_type: 'Compratore',
              user_dashboard: 'vendo',
            }
            break

          default:
            commonPayload = {
              ...commonPayload,
              user_type: 'Compratore',
              user_dashboard: 'compro',
            }
        }
      }

      if ($cookies.get(CookieName) !== undefined) {
        commonPayload.cookie_policy_accepted = $cookies.get(CookieName)
      }

      if (!ignoreFilters) {
        switch (true) {
          case ListingRoutesWithoutShowcaseEnum.includes(name):
          case PropertyRoutesEnum.includes(name):
          case name === 'vendo':
          case name === 'valuta-casa':
          case name === 'area-riservata-annunci-preferiti':
          case nuxtState.error?.statusCode === 404:
            return
        }
      }

      this.track({ ...commonPayload, ...payload })
    },
  }

  inject('tealium', Tealium)

  Tealium.pageTrack()
}

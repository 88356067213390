export const state = () => ({
  city: null,
})

export const mutations = {
  updateCity(state, payload) {
    state.city = payload
  },
}

export const actions = {
  async fetchCity({ commit }, slug) {
    const res = await this.$axios.get('/_api/api/v1/sell/city', {
      params: { slug },
    })

    commit('updateCity', res.data)
  },
}

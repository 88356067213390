export const state = () => ({
  search: null,
  favorite: null,
  hidden: null,
  hasReset: false,
})

export const mutations = {
  setSearch(state, payload) {
    state.search = payload
  },

  setFavorite(state, payload) {
    state.favorite = payload
  },

  setHidden(state, payload) {
    state.hidden = payload
  },

  setHasReset(state, payload) {
    state.hasReset = payload
  },
}

export const actions = {
  setSearch({ commit }, search) {
    commit('setSearch', search)
  },

  setFavorite({ commit }, favorite) {
    commit('setFavorite', favorite)
  },

  setHidden({ commit }, hidden) {
    commit('setHidden', hidden)
  },
}

/* eslint-disable camelcase */
import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode,
} from 'vee-validate'
import {
  required,
  alpha_spaces,
  email,
  confirmed,
  min,
} from 'vee-validate/dist/rules'

setInteractionMode('lazy')

extend('required', {
  ...required,
  message: 'Il campo è obbligatorio',
})
extend('confirmed', {
  ...confirmed,
  message: 'Le password non corrispondono',
})

extend('strongPassword', {
  message() {
    return `La password deve contenere almeno una lettera minuscola, una maiuscola, un numero e un carattere speciale`
  },
  validate(value) {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
    return value.match(regex)
  },
})

extend('min', {
  ...min,
  message: 'Le password è troppo corta',
})

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Il campo può contenere solo lettere',
})

extend('email', {
  ...email,
  message: 'Il campo deve contenere un email valida',
})

extend('phone', {
  message() {
    return `Inserisci un numero di telefono valido`
  },
  validate(value) {
    const regex = /^(?:[0-9+] ?){0,1}(?:[0-9] ?){7,}$/
    return value.match(regex)
  },
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

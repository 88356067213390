import { set } from 'vue'

export const state = () => {
  return []
}

export const mutations = {
  add(state, search) {
    state.unshift(search)
    state.length = state.length > 3 ? 3 : state.length
  },

  setLastFilter(state, filter) {
    set(state[0], 'filter', filter)
  },
}

export const getters = {
  get: (state) => state,
  getLast: (state) => state[0],
}

export const actions = {
  add({ commit }, search) {
    commit('add', search)
  },

  setLastFilter({ commit }, filter) {
    commit('setLastFilter', filter)
  },
}

import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'

const NODE_TARGET_TEMP = 'data-target-temp'

Vue.use(VueDOMPurifyHTML, {
  hooks: {
    beforeSanitizeAttributes(node) {
      const nodeIsA = node.tagName === 'A'

      if (!nodeIsA) {
        return
      }

      const nodeTarget = node.getAttribute('target')

      if (nodeTarget) {
        node.setAttribute(NODE_TARGET_TEMP, nodeTarget)
      }
    },

    afterSanitizeAttributes(node) {
      const nodeIsA = node.tagName === 'A'

      if (!nodeIsA) {
        return
      }

      if (node.hasAttribute(NODE_TARGET_TEMP)) {
        const nodeTargetTemp = node.getAttribute(NODE_TARGET_TEMP)

        node.setAttribute('target', nodeTargetTemp)
        node.removeAttribute(NODE_TARGET_TEMP)
      }
    },
  },
})

export const state = () => {
  return []
}

export const mutations = {
  add(state, property) {
    state.unshift(property)
    state.length = state.length > 4 ? 4 : state.length
  },
}

export const getters = {
  get: (state) => state,
  getPropertyById: (state) => (id) => {
    return state.find((it) => it.id === id)
  },
}

export const actions = {
  add({ commit }, property) {
    commit('add', property)
  },
}

import { set } from 'vue'

const defaultState = {
  balcony: 0,
  bathrooms: null,
  box: null,
  elevator: 0,
  garden: 0,
  geo: null,
  geoZones: [],
  price: {},
  rooms: {},
  surface: {},
  terrace: 0,
  typology: null,
  subTypology: null,
}

export const state = () => ({
  ...defaultState,
  section: null,
  sections: [],
  sort: null,
  typologies: [],
})

export const mutations = {
  reset(state) {
    Object.assign(state, defaultState)
  },

  updateSearch(state, payload) {
    set(state, payload.key, payload.value)
  },

  setGeo(state, geo) {
    set(state, 'geo', geo)
  },

  setGeoZones(state, geoZones) {
    set(state, 'geoZones', geoZones)
  },

  addGeoZone(state, zone) {
    const prevZones = state.geoZones.filter((it) => it.id !== zone.id)
    state.geoZones = [...prevZones, zone]
  },

  removeGeoZone(state, zone) {
    const prevZones = state.geoZones.filter((it) => it.id !== zone.id)
    state.geoZones = [...prevZones]
  },

  setSections(state, section) {
    set(state, 'sections', section)
  },

  setSection(state, section) {
    set(state, 'section', section)
  },

  setTypologies(state, typologies) {
    set(state, 'typologies', typologies)
  },

  setTypology(state, typology) {
    set(state, 'typology', typology)
  },

  setSubTypology(state, subTypology) {
    set(state, 'subTypology', subTypology)
  },

  setBathrooms(state, bathrooms) {
    set(state, 'bathrooms', bathrooms)
  },

  setBox(state, box) {
    set(state, 'box', box)
  },
}

export const getters = {
  getSection: (state) => state.section,
  getTypologies: (state) => state.typologies,
  getTypologyBySlug: (state) => (slug) => {
    return state.typologies.find((it) => it.slug === slug)
  },
  getFilter: (state) => {
    const zone = state.geoZones.map((it) => it.id).join(',')

    const rooms = getRanges(state, 'rooms')
    const surface = getRanges(state, 'surface')
    const price = getRanges(state, 'price')

    const filter = {
      ...(state.geo?.provincia ? { province: state.geo.provincia } : {}),
      ...(state.geo?.comune ? { city: state.geo.comune } : {}),
      ...(zone ? { zone } : {}),
      ...(state.typology ? { typology: state.typology.id } : {}),
      ...(state.bathrooms
        ? state.bathrooms.value === 4
          ? { 'bathrooms:min': 3 }
          : { bathrooms: state.bathrooms.value }
        : {}),
      ...(state.box
        ? { box: state.box.value === 0 ? 255 : state.box.value }
        : {}),
      ...rooms,
      ...surface,
      ...price,
      ...(state.garden ? { 'garden:min': state.garden } : {}),
      ...(state.balcony ? { 'balconies:min': state.balcony } : {}),
      ...(state.terrace ? { 'terraces:min': state.terrace } : {}),
      ...(state.elevator ? { 'elevators:min': state.elevator } : {}),
    }

    if (!Object.keys(filter).length) {
      return null
    }

    return filter
  },
}

export const actions = {
  reset({ commit }) {
    commit('reset')
  },

  async fetchTypologies({ getters, commit }) {
    const section = getters.getSection
    const typologiesRes = await this.$axios.get('/_api/api/v1/typologies', {
      params: { category: section.value },
    })

    const typologies = typologiesRes.data.data

    commit('setTypologies', typologies)
  },
}

function getRanges(state, propName) {
  if (!state[propName]?.from && !state[propName]?.to) {
    return
  }

  const { from, to } = state[propName]

  switch (true) {
    case Boolean(from && to):
      return { [`${propName}:in`]: `${from},${to}` }

    case Boolean(from):
      return { [`${propName}:min`]: from }

    case Boolean(to):
      return { [`${propName}:max`]: to }
  }
}

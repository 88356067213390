import { enabledPrivateRoutes } from '~/assets/js/mappings'

export const state = () => ({
  arDisabled: false,
  subnavOpen: false,
  doubleOptin: false,
  footerBottomSpaced: false,
})

export const mutations = {
  setArDisabled(state, payload) {
    state.arDisabled = payload
  },

  setSubnavOpen(state, payload) {
    state.subnavOpen = payload
  },

  setDoubleOptin(state, payload) {
    state.doubleOptin = payload
  },

  setFooterBottomSpaced(state, payload) {
    state.footerBottomSpaced = payload
  },
}

export const actions = {
  nuxtServerInit({ commit, state }, { route }) {
    const { auth } = state

    if (
      route.path.startsWith('/area-riservata') &&
      !enabledPrivateRoutes.includes(route.name) &&
      auth.loggedIn &&
      !auth.user.finalized
    ) {
      commit('setArDisabled', true)
    }
  },
}

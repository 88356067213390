export const state = () => ({
  cookie: null,
})

export const mutations = {
  setCookie(state, payload) {
    state.cookie = payload
  },
}

export const actions = {
  setCookie({ commit }, payload) {
    commit('setCookie', payload)
  },
}

export const getters = {
  getCookie: (state) => state.cookie,
}
